.agentReviews_card {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  padding: 20px;
  margin-bottom: 1rem;
}

.reviewerCard_profile img {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.reviewerCard_profile h6 {
  color: #222;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.reviewerCard_profile p {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 400;
}

.tagAbout_review {
  color: #7e7e7e;
  font-weight: 400;
}

.review_para {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
}

.rating_star {
  color: #113a00;
  margin: 0 5px 0 0;
}

.rating_name {
  color: #7e7e7e;
  margin: 0;
}

@media (max-width:768px){
  .agentReviews_card {
    width: 95%;
  }
}
