

.HomeDetaislcategory_container {
  column-count: 4;
}

.subCategory_container {
    margin-bottom: 0rem;
}

.sub_category{
  display: inline-block;
}

.category {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.category p {
  margin: 0rem 0 0 0.6rem;
  color: #222;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 600;
}





@media only screen and (max-width: 1600px){
  .HomeDetaislcategory_container {
  column-count: 3;
}
}

@media only screen and (max-width: 1200px){
  .HomeDetaislcategory_container {
    column-count: 2;
  }
}