.circle1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.circle2 {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
}

.circle1 img {
  width: 250px;
  height: 250px;
}

.circle2 img {
  width: 250px;
  height: 250px;
}

.AuthSide_logo {
  position: absolute;
  top: 25px;
  right: 25px;
}

.AuthSide_logo img {
  width: 82px;
  height: 60px;
}

.Auth_centerLogo {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Auth_centerLogo img {
  width: 136px;
  height: 99px;
}

.AuthAll_main {
  position: relative;
  height: auto;
  min-height: calc(100vh - 150px);
}

.AuthField_mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AuthField_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 478px;
  width: 95%;
  margin-top: 40px;
}

.Authmain_heading h1 {
  font-size: 56px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 40px;
}

.Authmain_heading h5 {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
  text-align: center;
}

.Authmain_heading span {
  color: var(--secondary-color);
}




.AuthButton {
  width: 100%;
  height: 53px;
  margin-top: 20px;
  border-radius: 10px;
  background: var(--primary-color);
  color: var(--white-color);
}

.Auth_externalLogin {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: #000;
  font-weight: 500;
}

.Forgot_email {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.Forgot_email a {
  text-decoration: none;
}

.Forgot_email p {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}
.Forgot_email p a {
  text-decoration: none;
}

.Authcustomer_service {
  margin-top: 2px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.Authcustomer_service p {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.Authcustomer_service span {
  text-decoration: underline;
}

.LoginCreate {
  margin-top: 30px;
}

.LoginCreate p {
  display: flex;
  color: var(--primary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.6px;
}

.LoginCreate span a {
  text-decoration: underline;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
}

.AuthMail_value {
  display: flex;
  margin: 5px 0px 25px 0px;
  width: 100%;
  justify-content: flex-start !important;
}

.AuthMail_value p {
  margin: 0px 5px 0px 0px;
  color: #76787a;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: flex-start !important;
}

.login_line {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.Loginor_Line {
  content: "__________________";
  width: 34%;
  height: 1.5px;
  background: #737373;
}

.loin_or {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.AuthFooterMain {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #eee;
}

.AuthFooterMain p {
  color: #7e7e7e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 50px;
  padding-top: 25px;
}

.planing_sell {
  width: 100%;
  justify-content: flex-start;
  margin-top: 40px;
}

.planing_sell p {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.planing_sell p {
  margin: 0px;
}

.BrokerFree p {
  width: 70%;
  text-align: left;
  color: var(--primary-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.5%;
}

.Auth_desciption p {
  color: #7e7e7e;
  font-weight: 400;
  line-height: 130.5%;
  margin: 0px;
}


.Forgot_Mail{
  margin: 0px;
  margin-top: 40px;
}