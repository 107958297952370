@keyframes intForm {
  from {
    transform: translateY(-10px);
    opacity: 0.8;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.AgentMain {
  height: auto;
  min-height: calc(100vh - 300px);
}

.Prof_mainContainer {
  max-width: 85%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.pageMain_heading h2 {
  color: var(--Black-222222, #222);
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.pageMain_heading p {
  color: var(--Dark-Gray, #7e7e7e);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SearchandButton {
  width: 100%;
  display: flex;
  gap: 12px;
}

.agentsCardSec {
  width: 100%;
}

.searchandLoc {
  width: 20%;
  border-radius: 10px;
  border: 1px solid #222;
  background: #fff;
  padding: 10px;
}

.searchandLoc input {
  border: none;
  outline: none;
  background-color: transparent;
  color: #222;
  width: 100%;
}

.searchandLoc input::placeholder {
  color: #222;
  font-size: 16px;
}

.profSearch {
  width: 25%;
}

.profSearch input {
  width: calc(100% - 25px);
}

.selectLang {
  width: 18%;
}

.selectSpecialities {
  width: 13%;
}

.selectRating {
  width: 9%;
}

.Agent_dropDown {
  width: 100%;
  background: #ffff !important;
  border-radius: 20px !important;
  padding: 12px !important;
  color: #222 !important;
}

.Agent_ProdropDown {
  width: 100%;
  background: #ffffff !important;
  border-radius: 6px !important;
  padding: 10px;
  color: #7e7e7e;
}

.AllAgent_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterAgent {
  width: 140px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.filterAgent p {
  margin: 0px;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.filterAgent:hover {
  border-radius: 20px;
  background: #f5f5f5;
  color: #222 !important;
}

.ActiveDiv p {
  color: #222;
}

.ActiveDiv {
  border-radius: 20px;
  background: #f5f5f5;
}

.Agent_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.Agent_card {
  max-width: calc(100% / 2 - 10px);
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 24px;
  border: 1px solid var(--Medium-Gray, #d9d9d9);
  padding: 20px;
  cursor: pointer;
}

.Agent_card:hover {
  border: 1px solid #113a00;
}

.Agent_cardprofile img {
  width: 78px;
  height: 78px;
  border-radius: 78px;
}

.AgentCallIcon {
  position: absolute;
  right: 15px;
}
.AgentCardContent {
  margin-left: 1rem;
  width: 100%;
}

.AgentCardContent h3 {
  color: #222;
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
}

.AgentTag {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}

.AgentPr_range {
  color: #676767;
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}

.AgentPr_range span {
  color: #113a00;
  font-weight: 600;
}

.AgentSale {
  margin: 2px 5px 12px 0px;
}

.AgentSale p {
  margin: 0 !important;
  font-size: 12px;
}

.AgentcitySale p {
  color: #113a00;
  font-weight: 700;
}

.Agent_Status {
  border-radius: 6px;
  background: #bde0fe;
  padding: 6px;
  width: fit-content;
}

.Agent_Status p {
  margin: 0 !important;
  color: #02549e;
  font-size: 10px;
  font-weight: 600;
}

.Agent_Company p {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.Agent_rating {
  display: flex;
  margin: 0.5rem 0px;
}

.esAgent_rating {
  margin: 0.3rem 0 0.5rem 0;
}

.Agent_rating p {
  margin: 0px !important;
  margin-left: 0.3rem !important;
  color: #113a00;
  font-size: 14px;
  font-weight: 600;
}

.esLan_speaking p {
  font-size: 12px;
  color: #7e7e7e;
  margin: 2px 0px 0px 1.5rem !important;
}

.Agent_rating span {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 400;
}

.advisorLan p {
  color: #676767;
  font-size: 12px;
  font-weight: 500;
  margin: 0px !important;
  margin-bottom: 0.4rem !important;
}

.AdvisorD_profile {
  margin-top: 9rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.img_container {
  width: 142px;
  height: 142px;
  border-radius: 142px;
  background-color: white;
  border: 1px solid grey;
  padding: 5px;
}

.img_container img {
  width: 100%;
  height: 100%;
  border-radius: 142px;
}

.AdvisorD_profile h3 {
  color: #000;
  font-size: 32px;
  font-weight: 700;
  margin-top: 0.5rem;
}

.AdvisorD_profile p {
  color: #676767;
  font-size: 16px;
  font-weight: 500;
  margin: 0px !important;
  margin-bottom: 0.2rem !important;
}

.AgentD_id {
  font-weight: 400;
}

.AdvisorDbtn {
  display: flex;
  gap: 15px;
  margin: 1rem 0rem;
}

.AdvisorDbtn button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  font-weight: 600;
  color: #113a00;
}

.AdvisorDMsg_btn {
  background-color: transparent;
  border: 0.5px solid #7e7e7e;
  color: #7e7e7e !important;
}

.AdvisorDCall_btn {
  background: linear-gradient(101deg, #b0d95f 9.38%, #eef323 82.54%);
}

.AdvisorD_timingD {
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  padding: 32px;
  height: fit-content;
}

.AdvisorD_timingD p {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.AdvisorD_lang {
  color: #113a00 !important;
  font-weight: 600 !important;
  padding: 0px 5px !important;
}

.AdvisorD_timingD h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.AdvisorD_loc {
  font-size: 20px;
  font-weight: 500;
  margin-left: 1rem !important;
}

.AdvisorCall_time {
  display: flex;
}

.AdvisorDay_call {
  width: 40%;
}

.Advisor_des h3 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.Advisor_des p {
  color: #676767;
  font-size: 16px;
  font-weight: 500;
}

.Advisor_map {
  width: 100%;
  height: 391px;
}

.Agent_profileDes {
  color: #676767;
  font-weight: 500;
}

.Agent_profileDes h3 {
  color: #000;
  font-size: 32px;
  font-weight: 700;
}

.Agent_profileDes p {
  margin: 0px;
}

.AgentD_rating {
  display: flex;
  align-items: center;
  margin: 0.7rem 0rem;
}

.AgentD_rating p {
  color: #113a00;
  font-weight: 600;
  margin: 4px 0px 0px 0.5rem;
}

.esAgent_links {
  display: flex;
  gap: 16px;
  margin: 0.8rem 0rem;
}

.esAgent_linkIcon {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  border: 0.5px solid #d9d9d9;
}

.EsAgent_sale {
  display: flex;
  font-size: 14px;
  gap: 20px;
}

.EsAgent_Price {
  display: flex;
  gap: 36px;
}

.EsAgent_des h2 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.EsAgent_des h5 {
  color: #222;
  font-size: 20px;
  font-weight: 500;
}

.EsAgent_des ul {
  list-style: none;
  margin: 0.5rem 0;
  padding: 0;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 500;
}

.EsAgent_des p {
  color: #7e7e7e;
  font-weight: 400;
}

.EsAgentListing h5 {
  color: #222;
  font-size: 20px;
  font-weight: 600;
}

.EsAgent_desMain {
  max-width: calc(100% - 450px);
  width: 100%;
}

.EsAgentcont_cardMain {
  width: 424px;
}

.EsAgentcont_card {
  width: 424px;
  padding: 20px;
  border-radius: 20px;
  background: #f5f5f5;
}

.EsAgentcont_profile {
  display: flex;
  width: 100%;
}

.EsAgentcont_profile img {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.EsAgentcont_profile h6 {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 170%;
  margin: 0;
}

.EsAgentcont_profile p {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.realEstate_logo img {
  width: 91px;
  height: 50px;
}

.EsSend_message {
  width: 100%;
}

.EsSend_message textarea {
  width: 100%;
  resize: none;
  margin-top: 0.3rem;
  border: none;
  outline: none;
  border-radius: 10px;
  background: #fff;
  padding: 8px 10px;
  color: #7e7e7e;
  font-size: 14px;
}

.EsSend_message button {
  width: 100%;
  padding: 14px 10px;
  border-radius: 24px;
  background: #222;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.esAgent_conBtn {
  color: #222 !important;
  background-color: #fff !important;
}

.SeeAllBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 2rem 0 0;
  align-items: center;
}

.SeeAllBtn button {
  background-color: transparent;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 500;
}

.Rating_Section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1.5rem 0;
}

.Addreview_btn button {
  border-radius: 6px;
  background: #222;
  text-align: center;
  display: flex;
  padding: 8px 40px;
  gap: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.Addreview_btn button:hover {
  background: #b0d95f;
  color: #000;
}

.Addreview_btn button:hover svg path {
  fill: #000 !important;
}

.Rating_heading h5 {
  color: #222;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.services_area a {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 500;
}

.services_area a:hover {
  color: #113a00;
}

.WriteRev_mainHeading h2 {
  color: #000;
  font-size: 48px;
  font-weight: 600;
}

.WriteRev_mainHeading p {
  color: #7e7e7e;
  font-weight: 400;
}

.WriteRevDes label {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 170%;
}

.WriteRevDes textarea {
  margin-top: 1rem;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  border-radius: 16px;
  border: 0.5px solid #d9d9d9;
  background: #f5f5f5;
  padding: 16px;
  color: #7e7e7e;
  font-size: 16px;
}

.GiveRating_stars {
  display: flex;
  align-items: center;
}

.GiveRating_stars label {
  color: #7e7e7e;
  font-size: 20px;
  font-weight: 500;
  width: 220px;
}

.rating_stars > * {
  margin-right: 1.5rem !important;
}

.ShareMore_details h5 {
  color: #222;
  font-size: 24px;
  font-weight: 600;
}

.ReviewsRadio_inputs label {
  display: flex;
  width: fit-content;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
}

.ReviewsRadio_inputs input[type='radio'] {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 0.15em solid #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
}

.ReviewsRadio_inputs input[type='radio']:hover {
  appearance: none;
  border: 5px solid #b0d95f;
}

.ReviewsRadio_inputs input[type='radio']:checked {
  appearance: none;
  border: 5px solid #b0d95f;
}

.ReviewsRadio_inputs input[type='radio']:checked + span {
  color: #222;
  font-weight: 500;
}

.ReviewsRadio_inputs input[type='checkbox'] {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 0.15em solid #d9d9d9;
  cursor: pointer;
}

.ReviewsRadio_inputs input[type='checkbox']:checked {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: black;
  border: none;
  outline: none;
}

.ReviewsRadio_inputs input[type='checkbox']:checked:after {
  appearance: none;
  content: '✔';
  color: white;
}

.ReviewsRadio_inputs input[type='checkbox']:checked + span {
  color: #222;
  font-weight: 500;
}

.ReviewsRadio_inputs span {
  margin-left: 0.7rem;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
}

.submit_reviewbtn button {
  margin-top: 3rem;
  border-radius: 10px;
  background: linear-gradient(102deg, #a5d448 -4.47%, #eef323 101.35%);
  padding: 10px 16px;
  color: #113a00;
  font-size: 14px;
  font-weight: 600;
}

.experience_form label {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.experience_form input {
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #949494;
  background: #f5f5f5;
  padding: 10px;
  width: 100%;
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.3rem;
}

.experience_form input:hover {
  border: 1.5px solid #113a00;
}

.experience_form input:focus {
  border: 1.5px solid #113a00;
}

.IntFormopen {
  animation: intForm 400ms ease-out backwards;
}

.FilterHeader_main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}

.FilterPagesHeader {
  max-width: 900px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FilterPagesHeader ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  color: #7e7e7e;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 0px;
  margin: 0;
}

.pagesFilter li {
  padding-bottom: 5px;
  cursor: pointer;
}

.pagesFilter li:hover {
  border-bottom: 1.5px solid #222;
  color: #222;
}

.ActivePage {
  border-bottom: 1.5px solid #222;
  color: #222;
}

.Builder_communities {
  display: flex;
  margin-top: 0.5rem;
}

.Builder_communities p {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
  margin: 2px 0 0 4px;
}

.Builder_plansPrice p {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.Builder_plansPrice span {
  color: #113a00;
  font-weight: 600;
}

.inspector_detdes {
  max-width: 70%;
  width: 100%;
  margin-top: 1.2rem;
}

.inspector_detdes p {
  text-align: justify;
}

.builder_spec {
  max-width: 70%;
  width: 100%;
}

.builder_spec h5 {
  color: #222;
  font-size: 20px;
  font-weight: 600;
}

.Builder_comHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Builder_comHeading h6 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.Builder_dropDown {
  width: 350px;
}

.Builders_commCards {
  max-width: 420px;
  width: 100%;
}

.Builders_map {
  height: 500px;
  width: calc(100% - 420px);
}

.BuilderCom_cardImg {
  width: 110px;
  height: 85px;
  border-radius: 16px;
}

.BuilderCom_cardImg img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.BuilderCom_cardDes h6 {
  color: #222;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 0.2rem 0;
}

.BuilderCom_cardDes p {
  margin: 0;
  color: #222;
  font-size: 16px;
  font-weight: 500;
}

.builderComm_address {
  color: #7e7e7e !important;
  margin-top: 0.5rem !important;
}

.BuilderCom_cardDes {
  margin: 0.2rem 0 0 0.8rem;
}

.break_line {
  background: #d9d9d9;
  color: #d9d9d9;
  height: 2px;
  margin-top: 1rem;
}

.check_now .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: green !important;
}

.Bread_Crumbs {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Bread_Crumbs p {
  margin: 0;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 1000px) {
  .EsAgent_desMain {
    max-width: 100% !important;
    width: 100%;
  }

  .EsAgentcont_cardMain {
    margin-top: 2rem;
    width: 95%;
  }
  .EsAgentcont_card {
    width: 100%;
  }
  .Agent_card {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .reviewsAll_cards {
    justify-content: center;
  }
}
