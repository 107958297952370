.prof_filter .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #222 !important;
}

.prof_filter .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #222 !important;
  color: var(--primary-color) !important;
}

.prof_filter .MuiOutlinedInput-input {
  color: #222 !important;
}

.prof_filter .MuiInputLabel-root{
  color: #222 !important;
}

.prof_filter .MuiInputLabel-root.Mui-focused {
  color: #222 !important;
}

.prof_filter .MuiSvgIcon-root {
  color: #222 !important;
}
