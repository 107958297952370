.swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.mySwiper2 {
  background: #222;
  width: 100%;
  height: calc(100vh - 160px);
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  color: white;
  font-size: 20px;

}

.swiperMainImg{
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  height: 100%;
}

.mySwiper2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.mySwiper2 {
  .swiper-button-next,
  .swiper-button-prev {
    color: #333;
    background-color: #fff;
    border-radius: 50%;
    width: 50px; 
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next{
    margin-right: 1rem;
  }

  .swiper-button-prev{
    margin-left: 1rem;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: #eeeeee;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 22px;
  }
}


.mySwiper {
  background: #2222;
  height: 90px;
  width: 93%;
  box-sizing: border-box;
  padding: 5px 0px;
}

.mySwiper .swiper-slide {
  width: 100px !important;
  height: 100%;
  opacity: 0.7;
  border-radius: 10px;
}

.mySwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 60px;
  border-radius: 10px;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper .swiper-slide-thumb-active img{
  border: 1px solid white;
  padding: 5px;
}


.mySwiper p{
  color: var(--White, #FFF);
  text-align: center;
  font-size: 0.7rem;
  font-weight: 500;
  margin-top: 0.2rem;
}


.swiper-pagination{
  text-align: right;
  margin-right: 5rem;
  bottom: 0;
  left: auto;
  font-family: Poppins;
  font-size: 1rem;
}