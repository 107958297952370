@import './themes/global.scss';




body {
  font-family: poppins;
}

.App {
  text-align: center;
}
