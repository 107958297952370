.sold-propAllcards {
  margin-top: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.columns_card1 {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  max-width: 145px;
  width: 100%;
}

.columns_card2 {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  max-width: 168px;
  width: 100%;
}

.sold-propertycard {
  border-radius: 20px;
  position: relative;
}

.sold-propertyCardPr {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%; /* Set left to 50% */
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  background: #222;
  padding: 6px;
  text-align: center;
  color: #fff;
  width: max-content;
}

.sold-propertyCardPr p {
  margin: 0 !important;
}

.card-1 {
  height: 274px;
  max-width: 135px;
  width: 100%;
}

.card-2 {
  width: 100%;
  height: 184px;
}

.card-3 {
  height: 118px;
  width: 100%;
}

.card-4 {
  width: 100%;
  height: 180px;
}

.card-5 {
  width: 100%;
  height: 172px;
}

.card-6 {
  max-width: 137px;
  width: 100%;
  height: 203px;
}

.sold-propertycard img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
