.Contact_main p {
  font-size: 16px;
  font-weight: 400;
  margin: 0px !important;
  max-width: 1000px;
  width: 100%;
}

.ContactUs_cover {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  background-color: #ffff;
}

.cover1 {
  position: absolute;
  left: 0;
  height: 100%;
}

.cover2 {
  position: absolute;
  right: 0;
  height: 100%;
}

.Conatct_mainHeading {
  z-index: 2;
}

.Conatct_mainHeading h1 {
  color: var(--secondary-color);
  font-size: 64px;
  font-weight: 700;
}

.Conatct_mainHeading p {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
}

.contacts_CardsMain {
  background-color: #f5f5f5;
  width: 100%;
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contacts_Cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 1150px;
  width: 100%;
}

.contact_card {
  width: 268px;
  border-radius: 20px;
  border: 0.5px solid #7e7e7e;
  background: #fff;
  padding: 15px 0px 10px 15px;
}

.ContentCard_icon {
  border: 0.5px solid #d9d9d9;
  padding: 8px;
  width: fit-content;
  border-radius: 8px;
}

.contact_cardCotent {
  margin-top: 2rem;
}

.contact_cardCotent p {
  text-align: start;
  color: var(--default-dark);
  font-weight: 600;
  font-size: 16px;
}

.contact_cardCotent p:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px !important;
  text-decoration: underline;
}

.ContactFaq_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

.ContactFaq {
  width: 100%;
  max-width: 800px;
}

.ContactFaq h1 {
  color: var(--default-dark);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.UnlockFeatures_content {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
}

.UnlockFeatures_content p {
  max-width: 800px;
  width: 100%;
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
}

.UnlockFeatures_content button {
  background-color: var(--default-dark);
  color: white;
  padding: 16px 32px;
  border-radius: 24px;
}
