.furnishCategory_container{
    column-count: 2;
    column-gap: 1.25rem;
}


.furnish_category ul{
    list-style: decimal;
}

.furnish_category{
    display: inline-block;
}