.PropertyHistoryTable_container{
    border-radius: 20px;
    background: #f5f5f5;
    max-width: 60rem;
    width: 100%;
    border-collapse: collapse;
    font-family: 'Inter';
    padding: 1rem;
    margin-top: 1.5rem;
}

.PropertyHistoryTable_container table{
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}


.PropertyHistoryTable_container table thead th{
    color: #222;
    font-size: 1rem;
    font-weight: 600;
    line-height: 95%; /* 15.2px */
    padding: 0.5rem 0 1rem 0;
}

.PropertyHistoryTable_container table thead th:nth-child(1){
padding-left: 1rem;
}

.PropertyHistoryTable_container table tbody{
    border-radius: 20px;
    background-color: #ffff;
}


.PropertyHistoryTable_container table tbody td:nth-child(1){
 padding-left: 1rem;
}
.PropertyHistoryTable_container table tbody td{
    color: #7E7E7E;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 95%; /* 13.3px */
    padding: 1.2rem 0;
}

