.galleryModal_main {
  background-color: #222;
  min-height: 100vh;
  color: #fff;
}

.gallery_des {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1.5rem 0.2rem 1.5rem;
  border-bottom: 1px solid #7e7e7e;
}

.gallery_des p {
  color: #fff;
  font-family: 'Inter';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  margin: 0;
}

.gallery_desPrice {
  color: #fff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  margin: 0;
}

.gallery_amenityDes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-left: 2rem;
}

.gallery_amenityDes p {
  font-size: 0.8rem;
  margin: 3px 0 0 0.3rem;
}

.gallery_btns {
  display: flex;
  padding: 10px;
  gap: 10px;
}

.gallery_btns button {
  display: flex;
  padding: 5px 10px;
  background-color: transparent;
  color: #7e7e7e;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
}

.gallery_btns button:hover {
  color: #ffff !important;
}

.ActiveBtn {
  color: #ffff !important;
  border-bottom: 1px solid white !important;
}

.AddDataBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.AddDataBtn button {
  background-color: #fff;
  color: #7e7e7e;
  border: none;
  outline: none;
}

.AddDataBtn button:hover {
  background-color: #f0f0f0;
}

.AddNoteBtn {
  padding: 8px 10px;
  border-radius: 32px;
  font-weight: 600;
}

.HeartBtn {
  border-radius: 50%;
  padding: 8px;
}

.HeartBtn:hover svg{
  stroke: #000 !important;
  width: 26px;
  height: 26px;
}

.HeartBtn:hover svg path {
  stroke: #000 !important;
}

.closeBtn {
  border-radius: 50%;
  padding: 4px;
}

.gallery_map {
  height: calc(100vh - 160px);
  width: 65%;
  margin-top: 0.4rem;
  margin-left: auto;
  margin-right: auto;
}

.gallery_mapZoomBtn {
  display: flex;
  gap: 10px;
  border-radius: 34px;
  background: #fff;
  padding: 10px;
  width: fit-content;
  color: #d9d9d9;
  font-size: 1rem;
}

.gallery_mapZoomBtn button {
  background-color: transparent;
}

.Map_satelliteBtn{
  padding: 10px;
  border-radius: 34px;
  background-color: #fff;
  margin-right: 2rem;
}



