.OverView {
  max-width: 50%;
  width: 100%;
}

.OverView p {
  color: #7e7e7e;
  text-align: justify;
  font-family: 'Inter';
  font-size: .75rem;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

@media only screen and (max-width: 1360px) {
  .OverView {
    max-width: 100%;
  }
}
