.Prop_Des p {
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.PropertySummaryDes p {
  margin: 0;
  margin-bottom: 0.2rem;
}

.Prop_Des ul {
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin: 0 0 .5rem 1rem;
}

.Prop_Des li {
  list-style: none;
  line-height: 200%;
}

.PropertySummaryDes a {
  color: #7e7e7e;
  text-decoration: underline;
}
