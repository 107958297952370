.FloorPlanCard {
  border-radius: 20px;
  background: #f5f5f5;
  padding: 1rem;
  max-width: 42rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.FloorCard_icon {
  border-radius: 10px;
  background: #a5d448;
  padding: 0.75rem;
  width: fit-content;
  align-items: center;
}

.card_des {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  gap: 0.2rem;
  justify-content: center;
}

.card_IconAndDes {
  display: flex;
  gap: 1rem;
}

.FloorPlanCard p {
  color: #7e7e7e;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
}

.card_IconAndDes p {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.card_arrow{
    background-color: #fff;
    border-radius: 50%;
    padding: 0.75rem;
    display: flex;
    align-self: flex-end;
}
