.prop_price {
  color: #222;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 45.6px */
  margin: 0;
}

.prop_Chip {
  border-radius: 24px;
  padding: 0.625rem;
  width: fit-content;
  margin-left: 0.5rem;
}

.prop_Chip h6 {
  font-weight: 600;
  font-size: 1rem;
}

.ListingBtns {
  display: flex;
  gap: 0.625rem;
}

.ListingBtns button {
  padding: 0.4rem;
  border-radius: 32px;
  background: #f5f5f5;
  color: #7e7e7e;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  border: none;
}

.desBtn_withcontent {
  padding: 0.4rem 0.625rem !important;
}

.AddressandAmenities {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.propValueAndAddress {
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  line-height: 170%;
}

.propValueAndAddress p {
  margin-bottom: 0;
}

.propValueAndAddress span a {
  color: #7e7e7e;
  text-decoration: underline;
}

.PropAmenities {
  display: flex;
  border-radius: 40px;
  background: #f5f5f5;
  padding: 1rem 1.5rem;
  gap: 1.5rem;
}

.PropAmenities p {
  color: #7e7e7e;
  font-size: 1rem;
  font-weight: 600;
}


.propertyOwnerLinks p{
    color: #7e7e7e;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.propertyOwnerLinks p a{
    text-decoration: underline;
}

.PropDes p{
    color: #7E7E7E;
    text-align: justify;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
}

@media screen and (max-width: 1400px) {
  .prop_price {
    font-size: 2rem;
  }
  .prop_Chip {
    padding: 0.5rem;
  }
  .prop_Chip h6 {
    font-size: 0.8rem;
  }
  .ListingBtns {
    gap: 0.3rem;
  }
  .propValueAndAddress {
    font-size: 0.8rem;
  }
  .PropAmenities {
    padding: 0.8rem 1.2rem;
  }
  .PropAmenities p {
    font-size: 0.8rem;
  }
  .ListingBtns button {
    font-size: 0.8rem;
  }
  .desBtn_withcontent svg{
    width: 20px !important;
    height: 20px !important;
  }
  .desBtn_withoutcontent svg{
    width: 20px !important;
    height: 20px !important;
  }
  .propertyOwnerLinks p{
    font-size: 0.8rem;
  }
}
