.gallery_container {
  display: flex;
  gap: 0.8rem;
  position: relative;
}

.gallery_container img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.galleryMainImg {
  max-width: calc(100% - 29rem);
  width: 100%;
  height: 20rem;
}

.gallery_subImg {
  max-width: 29rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  height: 20rem;
}

.sub_thumbnail {
  width: 100%;
  height: 48%;
}

.thumbail_subcatregorycontainer {
  display: flex;
  justify-content: space-between;
  height: 48%;
}

.thumbail_subcatregory {
  width: 48.5%;
  height: 100%;
}

.gallery_container button {
  border-radius: 6px;
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  color: #222;
}

.gallery_container button p {
  margin: 0;
}

.AllImgBtn {
  position: absolute;
  bottom: 0.7rem;
  right: 1rem;
}

.tourBtn {
  border: none;
  border-radius: 49px !important;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  position: absolute;
  top: 0.7rem;
  left: 1rem;
}

@media only screen and (max-width: 1360px) {
  .galleryMainImg {
    max-width: calc(100% - 22rem);
    height: 18rem;
  }
  .gallery_subImg {
    max-width: 22rem;
    width: 100%;
    height: 18rem;
  }
  .gallery_container button {
    font-size: 0.7rem;
    gap: 0.4rem;
  }
  .gallery_container button svg {
    width: 0.8rem;
    height: 0.8rem;
  }
  .AllImgBtn {
    right: 0.8rem;
  }
}
