.Ratingmain_container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.3rem;
}

.RatingChartandDes_container {
  display: flex;
  align-items: center;
  width: calc((100% - 0.55rem) / 2);
}

.Rating_des {
  margin-left: 0.5rem;
  display: flex;
}

.Rating_des p {
  margin-top: 1rem;
  color: #7e7e7e;
  text-align: justify;
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.Rating_categorydes {
  margin-top: 1.5rem;
  width: 100%;
}

.Rating_categorydes h3 {
  color: #222;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 700;
  line-height: 95%; /* 22.8px */
}

.category_container {
  column-count: 2;
  column-gap: 1.25rem;
}

.subCategory_container {
  display: inline-block;
  margin-bottom: 1rem;
}

.sub_category p {
  color: #7e7e7e;
  text-align: justify;
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin: 0;
}

.ProgressBar_container {
  width: calc((100% - 0.55rem) / 2);
}

.marketProgressBar {
  width: 100%;
  position: relative;
}

.marketProgressBar img {
  width: 100%;
}

.bar_tip {
  position: absolute;
  top: 0.2rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.Progress_ToolTip {
  position: absolute;
  top: -2rem;
  width: 5rem;
  height: 2rem;
}

.Progress_ToolTip img {
  width: 100%;
  height: 100%;
}

.market_headings {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.market_headings h6 {
  color: #222;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 600;
}

@media only screen and (max-width: 1360px) {
  .Ratingmain_container {
    flex-direction: column;
  }

  .RatingChartandDes_container {
    width: 100%;
  }

  .ProgressBar_container {
    width: 100%;
    margin: 2rem 0 0.5rem 0;
  }

  .category_container {
    column-count: 1;
  }

  .subCategory_container {
    margin-bottom: 0.5rem;
  }
}
