.highlight_container {
  max-width: 80%;
  min-width: 50rem;
  width: 100%;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 1.25rem;
  display: flex;
  gap: 3rem;
  font-family: 'Inter';
}

.highlight_container h6 {
  color: #222;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 95%; /* 13.3px */
}

.highlight_container p {
  color: #7e7e7e;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
