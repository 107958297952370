.Incentive_subcategorycontainer{
    margin-top: 1rem;
    display: flex;
    width: 60%;
    gap: 1.5rem;
}

.incentive_category{
    max-width: 49%;
    width: 100%;
}