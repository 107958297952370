

:root {
  --primary-font: 'poppins';
  --secondary-font: 'poppins';
  --primary-color: #113a00;
  --secondary-color: #a5d448;
  --white-color: #fff;
  --default-dark: #222;
  --default-light: #76787a;
  --error: #ff0000;
  --success: #5cb85c;
}

a {
  text-decoration: none;
}

.button {
  outline: none;
  border: none;
}

.fW-4 {
  font-weight: 400;
}
.fW-5 {
  font-weight: 500;
}
.fW-6 {
  font-weight: 600;
}
.fW-7 {
  font-weight: 700;
}
.fW-8 {
  font-weight: 800;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px !important;
}

.css-p0rm37 {
  top: -6px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}


.MuiInputLabel-root.Mui-focused{
  color: var(--primary-color) !important;
}

.Invaild_errors {
  font-size: 14px;
  color: var(--error);
}

.react-tel-input {
  height: 53px !important;
  width: 100% !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}

.react-tel-input .form-control {
  height: 100% !important;
  border: none !important;
  padding-left: 64px !important;
  width: 100% !important;
  border-radius: 10px !important;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  border: 1px solid var(--primary-color) !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-right: 1px solid #c8c8c8 !important;
  padding: 0px 8px 0px 8px !important;
}

.Footer_Main {
  background-color: #d9d9d9;
}

.footer_above {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.Footer_Main ul {
  padding: 0 !important;
  margin: 0 !important;
}

.Footer_Main h6 {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}

.Footer_Main li,
.Footer_Main li a,
.Footer_Main p {
  list-style: none;
  color: #7e7e7e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  margin-bottom: 0.5rem;
}

.Footer_Main a {
  width: fit-content;
}

.footer_copyRight {
  border-top: 1px solid #7e7e7e;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Footer_des {
  max-width: 250px;
  width: 100%;
}

.FooterContact_des {
  max-width: 320px;
  width: 100%;
}

