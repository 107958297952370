.main {
  max-width: 85%;
  margin: auto;
  width: 96%;
  height: auto;
  min-height: calc(100vh - 350px);
}

.Main_heading {
  width: 100%;
}

.Main_heading h1 {
  color: var(--default-dark);
  font-size: 48px;
  font-weight: 700;
  line-height: 95%;
}

.cardsAll_main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 2rem;
  gap: 18px;
}

.Notes_card {
  border-radius: 20px;
  border: 0.5px solid #d9d9d9;
  background: #f5f5f5;
  max-width: 440px;
  width: 100%;
  height: 346px;
  padding: 20px;
  color: #7e7e7e;
  font-family: 'Inter';
}

.propertyImg {
  width: 120px;
  height: 100px;
  border-radius: 16px;
}

.propertyImg img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.Notesprop_card {
  border-radius: 16px;
  background: #fff;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
}

.PropPrice {
  color: #222;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0.1rem;
}

.PropName {
  color: #222;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.PropAddress {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 0.5rem 0;
}

.Propdetails {
  display: flex;
  gap: 1.2rem;
}

.Propdetails p {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 0.1rem;
}

.NotesDes {
  margin-top: 1.3rem;
  height: 90px;
  overflow: hidden;
}

.NotesDes p {
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.FullViewBtn {
  border-radius: 24px;
  border: 1px solid #222;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 3rem;
  gap: 10px;
  color: #222;
  font-size: 14px;
  font-weight: 600;
}

.DeleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 3.5rem;
  gap: 10px;
  border-radius: 24px;
  background: #222;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.Modal_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Modal_heading h3 {
  color: #222;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.CloseIcon:hover {
  background-color: #ececec;
  border-radius: 50%;
  cursor: pointer;
}

.ModalTextera {
  border: none;
  outline: none;
  width: 100%;
  color: #7e7e7e;
  text-align: justify;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  resize: none;
}

.ModalTextera[disabled] {
  background-color: inherit;
}


textarea::-webkit-scrollbar {
  width: 0.3em;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgb(214, 214, 214);
}

@media (max-width: 768px) {
  .main {
    max-width: 100%;
    width: 100%;
    padding: 0px 20px;
  }
}
