.listing_mainContainer {
  max-width: 94%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  height: auto;
}


.ListingDesandContact_main{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 2rem;
}


.ListingDes_main{
  width: calc(100% - 29rem);
}

.ContactAgent_main{
  max-width: 28.125rem;
  width: 100%;
}

.PropDetails_hedings {
  color: #222;
  font-family: 'Inter';
  font-size: 2rem;
  font-weight: 700;
  line-height: 95%; /* 30.4px */
  margin-bottom: 1rem;
}

.sub_headings{
    color: #222;
    font-family: 'Inter';
    font-size: 1rem;
    font-weight: 600;
    line-height: 120%; /* 15.2px */
    margin-top: 0.5rem;
  
}

.ListingDetails_listMain{
  color: #7e7e7e;
  font-size: 0.75rem;
  padding-left: 1.3rem;
}

.ListingDetails_listMain li{
  line-height: 150%;
}


.community_des{
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: .75rem;
}

@media only screen and (max-width:1360px){
  .ListingDes_main{
    width: calc(100% - 23rem);
  }
  .ContactAgent_main{
    max-width: 22rem;
  }
}

