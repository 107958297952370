.fee_subcategorycontainer {
  margin-top: 1rem;
  display: flex;
  width: 60%;
  gap: 1.5rem;
}

.fee_category {
  max-width: 49%;
  width: 100%;
}

.fee_headAndDes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fee_category ul {
  padding-left: 0;
}

.fee_category li {
  list-style: none;
  line-height: 200%;
}

.fee_category p {
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin: 0;
}
