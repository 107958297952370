.scoreCard_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.score_card {
  max-width: calc((100% - 1rem)/2);
  width: 100%;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Inter';
  align-items: flex-end;
}

.score_card h3 {
  color: #222;
  font-size: 1rem;
  font-weight: 600;
  line-height: 95%; /* 15.2px */
  margin: 0 0 1rem 0;
}

.score_card p {
  color: #7e7e7e;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 95%; /* 13.3px */
  margin: 0;
}

.total_score_container {
  display: flex;
  padding: 5px;
  align-items: baseline;
  border-radius: 16px;
  background: #fff;
  height: fit-content;
}

.obtain_score {
  color: #222;
  font-size: 1rem;
  font-weight: 700;
  line-height: 95%; /* 15.2px */
}

.total_score {
  color: #7e7e7e;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 95%;
}

.drive_distance {
  color: #7e7e7e;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 95%; /* 13.3px */
}

@media only screen and (max-width:1360px){
  .transitScore_card{
    max-width: 75%;
    width: 100%;
  }
}
