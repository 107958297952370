.mlsData_des{
    column-count: 2;
    column-gap: 1.25rem;
}


.mlsData_category {
  display: inline-block;
}




