.About_main h2 {
  color: var(--default-dark);
  font-size: 32px;
  font-weight: 700;
}

.About_main p {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
  margin: 0px !important;
  max-width: 1000px;
  width: 100%;
  text-align: center;
}

.AboutUs_cover {
  position: relative;
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutUs_cover img {
  width: 100%;
  opacity: 0.5;
}

.About_mainHeading {
  position: absolute;
}

.About_mainHeading h1 {
  color: var(--secondary-color);
  color: #a5d448;
  font-size: 64px;
  font-weight: 700;
}

.withbg_AboutContent {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
}

.exbg_AboutContent {
  background-color: white;
}

.AboutProfile_content {
    max-width: 1000px;
  display: flex;
  flex-direction: row;
}

.About_profile{
margin-right: 40px;
}

.AboutProfile_content h2 {
  text-align: start;
}

.AboutProfile_content p {
  text-align: start;
}

.UnlockFeatures_content {
  background-color: var(--secondary-color);
}

.UnlockFeatures_content p {
    max-width: 800px;
    width: 100%;
  color: var(--primary-color);
  font-weight: 600;
}

.UnlockFeatures_content button {
  background-color: var(--default-dark);
  color: white;
  padding: 16px 32px;
  border-radius: 24px;
}
