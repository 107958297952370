.Auth_Inputs {
  width: 100%;
}

.Auth_Inputs input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  padding: 14px 0px 14px 10px;
}

.Auth_Inputs input::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: var(--default-light);
}

.Auth_Inputs input:focus {
  border: none;
  outline: none;
  border: 1px solid var(--primary-color);
}

.Auth_eyeInputs {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: 53px;
  padding-left: 10px;
  padding-right: 10px;
}

.AuthPassBorder {
  border: 1px solid #c8c8c8;
}

.AuthPassFocusBorder {
  border: 1px solid var(--primary-color);
}

.Auth_eyeInputs input {
  width: calc(100% - 25px);
  height: 100%;
  border: none;
}

.Auth_eyeInputs input:focus {
  outline: none !important;
  border: none !important;
}

.PassVisibility{
  color: #76787A;
   font-size: 22px;
   cursor: pointer
}

.termsToAgree span{
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 400;
}

