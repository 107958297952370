.Article_cover {
  position: relative;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Article_cover img {
  width: 100%;
  opacity: 0.8;
}

.Article_coverContent {
  position: absolute;
}

.Article_coverContent h1 {
  color: #fff;
  font-size: 64px;
  font-weight: 800;
}

.Blogs_mainPara {
  display: flex;
  justify-content: center;
}

.Blogs_mainPara p {
  max-width: 1000px;
  width: 100%;
  color: #222;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.ArticleCard_filterSec {
  max-width: 95%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Article_filter {
  width: 20%;
}

.ArticleCardSec {
  width: 78%;
  padding-right: 2rem;
}

.artile_Searching {
  width: 100%;
  background: #f5f5f5;
  border-radius: 28px;
  padding: 15px;
}

.artile_Searching input {
  width: calc(100% - 25px);
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: #7e7e7e;
}

.BlogPathBtn {
  width: 100%;
  border-radius: 28px;
  background: #f5f5f5;
  padding: 15px;
  color: #222;
  font-size: 16px;
  font-weight: 500;
}

.ActiveBtn {
  background: linear-gradient(92deg, #a5d448 4.27%, #f3f520 100%);
}

.BlogPathBtn:hover {
  background: linear-gradient(92deg, #a5d448 4.27%, #f3f520 100%);
}

.AllArticleRead_cards {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}

.ArticleCard {
  max-width: 308px;
  width: 90%;
  border-radius: 20px;
  border: 0.5px solid #d9d9d9;
  background: #fff;
  padding: 15px;
  cursor: pointer;
}

.ArticleCard:hover {
  box-shadow: 2px 2px 10px #c9c9c9;
  transform: translateY(-1px);
  transition: transform 0.3s ease;
}

.ArticleLsCard {
  position: relative;
  width: 100%;
  height: 249px;
  display: flex;
  max-width: 100%;
  margin-bottom: 1.3rem;
}

.ArticleLsCard_Image {
  height: 212px;
  border-radius: 20px;
}

.ArticleLsCard_Image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.categoryAndRead {
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  align-items: center;
  font-family: 'Montserrat';
}

.categoryAndRead h6 {
  margin: 0;
}

.ArticleCard_category {
  border-radius: 27px;
  background: #f5f5f5;
  padding: 8px;
}

.ArticleCard_Image {
  margin-top: 0.7rem;
  width: 100%;
  height: 172px;
  border-radius: 20px;
}

.ArticleCard_Image img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.ArticleCardTitleDes h3 {
  margin-top: 1rem;
  color: #222;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
}

.ArticleCardTitleDes p {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
  font-family: 'Montserrat';
}

.ArticleLsCardTitleDes h3 {
  margin-top: 0.7rem;
  color: #222;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 82px;
}

.ArticleLsCardTitleDes p {
  margin: 0;
  font-size: 12px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.ArticleCardcont_read {
  position: absolute;
  bottom: 12px;
  right: 20px;
}

.ArticleCardcont_read p {
  margin: 0;
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
}

@media (max-width: 1200px) {
  .ArticleLsCardTitleDes p {
    -webkit-line-clamp: 4;
  }
}

@media (max-width: 990px) {
  .ArticleCard_filterSec {
    width: 94%;
    display: flex;
    justify-content: center;
  }

  .Article_filter {
    width: 100%;
  }

  .ArticleCardSec {
    width: 100%;
    padding: 0;
    margin-top: 2rem;
  }
}

@media (max-width: 673px) {
  .ArticleCard {
    max-width: 100%;
    width: 100%;
  }
}
