.contactAgentCard_main {
  border-radius: 20px;
  border: 1px solid var(--Medium-Gray, #D9D9D9);
  background: var(--White, #FFF);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  font-family: 'Inter';
  padding: 1.25rem;
}

.card_Profile {
  display: flex;
}

.card_PrImage {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.card_PrImage img {
  width: 100%;
  height: 100%;
  border-radius: 48px;
}

.Person_name {
  color: #222;
  font-weight: 600;
  line-height: 170%;
  margin: 0 0 0rem 0;
  font-size: 1rem;
}

.Person_status {
  color: #7e7e7e;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  margin: 0;
}

.Person_portfolioHeding {
  margin: 0.7rem 0 0 0;
  color: #222;
  font-weight: 400;
  line-height: 170%;
  font-size: 1rem;
}

.contactForm {
  margin-top: 0.7rem;
}

.agentName_mail{
  display: flex;
  gap: 0.5rem;
}

.ContactInputs_div {
  width: 48%;
}

.ContactInputs_div label {
  display: none;
}

.ContactInputs_div input {
  padding: 0.563rem 0.563rem !important;
  font-size: 0.875rem !important;
  color: #7e7e7e !important;
  border: 1px solid var(--Medium-Gray, #D9D9D9) !important;
}

.ContactInputs_div input::placeholder {
  font-size: 0.875rem !important;
}

.message {
  padding: 0.563rem;
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid var(--Medium-Gray, #d9d9d9);
  color: #7e7e7e;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  resize: none;
}

.message:focus {
  border: none;
  outline: none;
  border: 1px solid #113a00;
}

.meeting_Arrange {
  margin-top: 1rem;
  display: flex;
}

.meeting_Arrange label {
  display: flex;
  cursor: pointer;
}

.meeting_Arrange span {
  color: var(--Black-222222, #222);
  font-family: 'Montserrat';
  font-size: 0.875rem;
  font-weight: 600;
}

.meeting_Arrange input[type='checkbox'] {
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 0.15em solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
}

.meeting_Arrange input[type='checkbox']:checked {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: black;
  border: none;
  outline: none;
}

.meeting_Arrange input[type='checkbox']:checked:after {
  appearance: none;
  content: '✔';
  color: white;
}

.AgentCard_contactBtn {
  display: flex;
  width: 100%;
  gap: .625rem;
  margin-top: 1rem;
}

.AgentCard_contactBtn button {
  font-family: 'Poppins';
  font-size: 0.875rem;
  font-weight: 600;
  width: 49%;
  padding: 0.7rem 0;
  border-radius: 24px;
}

.sendMsg_btn {
  border: 1px solid #222;
  background: #fff;
  color: #222;
}

.ReqCall_btn {
  background: #222;
  color: #fff;
}

.dateAndTimeSchedule {
  margin-top: 0.3rem;
  display: flex;
  gap: 0.625rem;
}

.Agent_card_date_and_time {
  width: 49%;
}

.Agent_card_date_and_time .MuiInputBase-root {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  color: #7e7e7e;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 170%; /* 23.8px */
}

.Agent_card_date_and_time .MuiInputBase-root .MuiOutlinedInput-input{
  height: 0.8rem;
}

.AgentCont_inp .react-tel-input {
  height: 40px !important;
  border: 1px solid var(--Medium-Gray, #D9D9D9) !important;
}

.AgentCont_inp .react-tel-input .form-control {
  padding-left: 3.125rem !important;
  width: 100% !important;
}

.AgentCont_inp .react-tel-input .flag-dropdown {
  padding: 0px !important;
}

.form-control:focus {
  border: 1px solid var(--primary-color) !important;
}

@media only screen and (max-width:1360px){
  .agentName_mail{
    flex-direction: column;
    gap: 0.8rem;
  }
  .ContactInputs_div{
    width: 100%;
  }

  .AgentCard_contactBtn{
    flex-direction: column;
  }

  .AgentCard_contactBtn button {
    width: 100%;
  }
}