.main{
  max-width: 85%;
  margin: auto;
  width: 96%;
  height: auto;
  min-height: calc(100vh - 350px);
}

.Main_heading{
  width: 100%;
}

.Main_heading h1 {
  color: var(--default-dark);
  font-size: 48px;
  font-weight: 700;
  line-height: 95%;
}

.Main_heading p {
  color: #7e7e7e;
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
  width: 100%;
  max-width: 950px;
  margin: 0px !important;
  margin-top: 20px !important;
}

.cardsAll_main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2rem;
  gap: 18px;
}

.card {
  max-width: 440px;
  width: 100%;
  border-radius: 20px;
  background: #f5f5f5;
  padding: 20px;
}

.cardHeading {
  display: flex;
  align-items: center;
}

.cardHeading h4 {
  color: #222;
  font-size: 24px;
  font-weight: 600;
}

.cardHeading p {
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 400;
  margin: 0px !important;
  padding-left: 8px;
}

.card_Profile {
  display: flex;
  align-items: center;
}

.card_Profile img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
}

.card_Profile h6 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin: 0px !important;
  margin-bottom: 2px !important;
}

.card_Profile p {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 400;
  margin: 0px !important;
}

.Share_card p {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.Share_card button {
  width: 100%;
  border-radius: 24px;
  background: #fff;
  padding: 12px;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  margin-top: 1.7rem;
}

.Share_card input {
  width: 100%;
  border-radius: 21px;
  border: none;
  outline: none;
  padding: 12px 25px;
  color: #7e7e7e;
}

.Share_card input::placeholder {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
}

.invite_btn {
  background-color: var(--default-dark) !important;
  color: white !important;
  margin-top: 15px !important;
}

@media (max-width: 768px) {
  .main {
    max-width: 100%;
    width: 100%;
    padding: 0px 20px;
  }
}
